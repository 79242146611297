<!-- tag点击模块 -->
<!-- 接收数据格式 -->
<!-- [
    {
        label:'水电费水电费',
        value:'0'
    }
] -->
<template>
  <div class="tagModelPage">
    <div class="tagScrollBox">
      <div
        class="tagModelPageOpt"
        v-for="(navItem, navIndex) in navList"
        :key="navIndex"
        @click="setValue(navItem.value)"
        :class="{ active: swiperModel == navItem.value }"
      >
        {{ navItem.label }}
        <div class="bottomBorder"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance } from "vue";
// defineProps 用来接收父组件传来的值props。
// defineEmits 用来声明触发的事件表。
// useContext 用来获取组件上下文context。

const props = defineProps({
  navList: {
    default: [],
  },
  swiperModel: {
    default: null,
  },
});
const emits = defineEmits(["update:swiperModel"]);
const setValue = (value) => {
  emits("update:swiperModel", value);
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.tagModelPage {
  height: 68px;
  background-color: white;
  overflow: auto;
  .tagScrollBox {
    height: 100%;
    min-width: max-content;
    display: flex;
    .tagModelPageOpt {
      flex: 1;
      padding: 0 20px;
      white-space: nowrap;
      margin: 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 28px;
      .bottomBorder {
        display: none;
        position: absolute;
        bottom: 0;
        letter-spacing: 50%;
        transform: translateY(-50%);
        width: 30px;
        background-color: #b4272b;
        height: 6px;
      }
    }
    .active {
      color: #b4272b;
      .bottomBorder {
        display: block;
        border-radius: 3px;
      }
    }
  }
}
</style>
