<!-- 显示附加服务弹窗 仓库模块使用 -->
<!-- 接收数据格式 -->
<!--[
        {
    "id": 15,
    "name": "拍照",
    "name_translate": "拍照",
    "price": "1.00",
    "price_type": 0,
    "unit": "元",
    "is_overstep": 0,
    "auto_calculate": 0,
    "remark": "拍照",
    "sort": 0,
    "num": 2,
    "edit": true,
    "checked": true,
    "total": 2
}
    ] -->
<template>
  <div>
    <van-action-sheet v-model:show="show" :title="$fanyi('附加服务查看')">
      <div class="modelPage">
        <ul class="optionList">
          <li
            v-for="(optionItem, optionIndex) in optionData"
            :key="optionIndex"
          >
            <div>
              <p class="row1">
                <span class="boldFOnt"
                  >{{ optionItem.sort + 1 }})
                  {{ optionItem.name_translate }}</span
                >
              </p>
              <p class="row2">
                <span class="priceNum">
                  {{ optionItem.price }}
                </span>
                <span class="danWei">
                  {{ $fanyi("元/点") }}
                </span>
              </p>
            </div>
            <div>
              <p class="row1">
                <span class="boldFOnt">
                  {{ $fanyi("数量") }}：{{ optionItem.num }}
                </span>
              </p>
              <p class="row2">
                <span> {{ $fanyi("小计") }}： </span>
                <span class="redFont">
                  {{ $fun.ceil(optionItem.price * optionItem.num) }}元

                  <span>
                    ({{
                      $fun.roundNumber(
                        optionItem.price *
                          optionItem.num *
                          $store.state.exchangeRate
                      )
                    }}円)
                  </span>
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { getCurrentInstance, ref } from "vue";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    const show = ref(false);
    const optionData = ref({});
    const open = (data) => {
      show.value = true;
      optionData.value = data;
    };
    return {
      show,
      optionData,
      open,
    };
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.modelPage {
  margin: 0 30px;

  .optionList {
    min-height: 369px;
    li {
      padding: 30px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: solid 1px #dfdfdf;
      * {
        font-size: 24px;
        line-height: 42px;
      }
      .boldFOnt {
        font-weight: 500;
      }
      .priceNum {
        display: inline-block;
        width: 80px;
        height: 42px;
        background: #f2f2f2;
        border-radius: 6px;
        text-align: center;
        margin-right: 10px;
      }
      .redFont {
        color: #b4272b;
        font-weight: 600;
      }
      .row1 {
        margin-bottom: 10px;
      }
      .row2 {
        min-width: 250px;
      }
    }
  }
}
</style>
